.courses {
  .card-image {
    // clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
    // height: 250px;
    background-repeat: no-repeat;
    background-size: cover;
    height: 220px;
    overflow: hidden;
    transition: 2s ease-in-out;
  }

  .card {
    // clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
    // height: 250px;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgb(255, 255, 255);
    min-height: 290px;
    max-height: 450px;
    text-align: center;
    border-radius: 1px;
    overflow: hidden;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: -0.5px solid rgb(100, 100, 100);
    transition: all 1s ease 0s;
    color: rgb(66, 66, 66);
    text-decoration: none;
    border-radius: 5px;
    .description {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      //   padding: 10px;
      margin-left: 20px;
      margin-top: -30px;
    }
    h6 {
      margin-top: 25px;
      font-size: 22px;
      font-family: system-ui;
      color: black;
      text-transform: uppercase;
      font-weight: 600;
      text-shadow: black;
    }
    .category {
      margin-top: -10px;
      font-size: 18px;
      font-family: cursive;
      color: #d17639;
      text-transform: capitalize;
      font-weight: 400;
      text-shadow: black;
    }
    .price {
      margin-top: -32px;
      font-size: 22px;
      /* font-family: cursive; */
      color: white;
      text-transform: capitalize;
      font-weight: 800;
      background: rgb(102, 102, 102);
      width: 30%;
      border-top-right-radius: 15px;
    }
    .small-detail {
      margin-top: -10px;
      font-size: 12px;
      /* font-family: cursive; */
      color: rgb(155, 155, 155);
      text-transform: capitalize;
    }
    a {
      margin: 10px 20px 20px;
      /* background-color: orange; */
      font-size: 15px;
      /* width: 50%; */
      align-items: center;
      align-self: center;
      border-radius: 4px;
      padding: 8px 15px;
      font-weight: 500;
      color: rgb(95, 95, 95);
      text-decoration: none;
      border: 1px solid;
      transition: 0.2s ease;
    }
  }
  .card a:hover {
    background-color: orange;
    color: white;
    border-color: white;
    padding: 8px 20px;
    font-size: 16px;
  }
  // background-color: #f7f7f7 !important;
  .card,
  .left-card {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.07);

    // background-color: #2c2e94;
    .image {
      height: 250px;
      background-position: center;
      background-size: cover;
    }
    .details {
      padding: 16px;
      .heading {
        a {
          color: $secondary;
          transition-duration: 0.3s;
          font-weight: 600;
        }
        a:hover {
          color: $primary;
        }
      }
      .summaryBox {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 20px;
      }
      .btn {
        background: linear-gradient(to right, #ef3e0f, #ffb800);
        border-radius: 0 !important;
        outline: 0;
        border: 0;
        width: 50%;
      }
      .btn:hover {
        opacity: 0.85;
      }
    }
    .ribbon {
      background-color: #ffc107;
      -webkit-box-shadow: 0 1px 0px rgba(0, 0, 0, 0.07);
      box-shadow: 0 1px 0px rgba(0, 0, 0, 0.07);
      border-radius: 0;
      color: $secondary;
      display: block;
      font-size: 20px;
      position: absolute;
      padding: 7px 15px;
      top: 20px;
      left: -15px;
      font-weight: bold;
    }

    .ribbon:after {
      content: '';
      position: absolute;
      top: auto;
      left: 4px;
      right: auto;
      bottom: -7px;
      background: #bb7a03;
      -webkit-transform: rotate(35deg);
      transform: rotate(35deg);
      height: 20px;
      width: 20px;
      z-index: -1;
    }
    .item-underlined {
      border-bottom: 0.5px solid #c2c2c2;
    }
    .item-underlined:last-child {
      border-bottom: none;
    }
    .category-title {
      font-size: 20px;
      color: #212529;
      cursor: pointer;
    }
    .category-title:hover {
      color: #fc8200;
      text-decoration: none;
    }
  }
  .title-underlined {
    border-bottom: 2px solid #ff9a02;
  }
}

@media (max-width: 991px) {
  .courses {
    .card {
      .details {
        .btn {
          width: 100%;
        }
      }
    }
  }
}

.trainer-card {
  .card-image {
    // clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
    // height: 250px;
    background-repeat: no-repeat;
    background-size: cover;
    height: 200px;
    overflow: hidden;
    transition: 2s ease-in-out;
    width: 200px;
    border-radius: 50%;
    align-self: center;
  }

  .card {
    background-repeat: no-repeat;
    background-size: cover;
    // background-color: rgb(255, 255, 255);
    // min-height: 290px;
    // max-height: 450px;
    text-align: center;
    overflow: hidden;
    transition: all 1s ease 0s;
    text-decoration: none;
    border: 0px;
    box-shadow: none;

    .description {
      display: flex;
      margin-top: 20px;
      flex-direction: column;
      align-items: center;
      width: 90%;
      align-self: center;
      .item {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        .key {
          font-weight: 600;
          color: rgb(145, 145, 145);
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        .value {
          font-weight: 600;
          color: black;
          text-transform: capitalize;
        }
      }
    }
    .buttonCont {
      width: 90%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      border-bottom: 1px solid rgb(192, 192, 192);
      align-self: center;
      padding-bottom: 10px;
    }
    h6 {
      font-size: 22px;
      font-family: system-ui;
      color: black;
      text-transform: uppercase;
      font-weight: 600;
      text-shadow: black;
    }
    .category {
      font-size: 22px;
      font-family: cursive;
      color: #d18f39;
      text-transform: capitalize;
      font-weight: 600;
      text-shadow: black;
      padding-top: 10px;
    }
    .price {
      color: rgb(0, 0, 0);
      border-radius: 20px;
      background-color: rgb(255, 239, 239);
      border-radius: 15px;
      align-self: center;
      padding: 4px 12px;
      margin-top: -10px;
    }
    .small-detail {
      font-size: 12px;
      /* font-family: cursive; */
      color: rgb(155, 155, 155);
      text-transform: capitalize;
    }
    a {
      font-size: 15px;
      background-color: orange;
      align-items: center;
      align-self: center;
      border-radius: 4px;
      padding: 8px 15px;
      font-weight: 600;
      color: rgb(255, 248, 248);
      text-decoration: none;

      transition: 0.2s ease;
      width: 40%;
      cursor: pointer;
    }
    .back {
      font-size: 15px;
      align-items: center;
      align-self: center;
      border-radius: 4px;
      padding: 8px 15px;
      font-weight: 600;
      background-color: rgb(255, 255, 254);
      color: rgb(138, 136, 136);
      text-decoration: none;
      border: 1px solid rgb(146, 144, 144);
      transition: 0.2s ease;
      width: 40%;
    }
    .back:hover {
      background-color: rgb(151, 148, 148);
      color: rgb(255, 255, 255);
    }
  }
  // background-color: #f7f7f7 !important;
}

@media (max-width: 991px) {
  .trainer-card {
    .card {
      .details {
        .btn {
          width: 100%;
        }
      }
    }
  }
}

.myBook {
  .card-image {
    // clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
    // height: 250px;
    background-repeat: no-repeat;
    background-size: cover;
    height: 220px;
    overflow: hidden;
    transition: 2s ease-in-out;
  }

  .card {
    // clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
    // height: 250px;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgb(255, 255, 255);
    min-height: 290px;
    max-height: 450px;
    text-align: center;
    border-radius: 1px;
    overflow: hidden;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: -0.5px solid rgb(100, 100, 100);
    transition: all 1s ease 0s;
    color: rgb(66, 66, 66);
    text-decoration: none;
    border-radius: 5px;
    .description {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      //   padding: 10px;
      margin-left: 20px;
      margin-top: -30px;
    }
    h6 {
      margin-top: 25px;
      font-size: 22px;
      font-family: system-ui;
      color: black;
      text-transform: uppercase;
      font-weight: 600;
      text-shadow: black;
    }
    .category {
      margin-top: -10px;
      font-size: 18px;
      font-family: cursive;
      color: #d17639;
      text-transform: capitalize;
      font-weight: 400;
      text-shadow: black;
    }
    .price {
      margin-top: -32px;
      font-size: 22px;
      /* font-family: cursive; */
      color: white;
      text-transform: capitalize;
      font-weight: 800;
      background: rgb(102, 102, 102);
      width: 30%;
      border-top-right-radius: 15px;
    }
    .small-detail {
      margin-top: -10px;
      font-size: 12px;
      /* font-family: cursive; */
      color: rgb(155, 155, 155);
      text-transform: capitalize;
    }
    a {
      margin: 10px 20px 20px;
      /* background-color: orange; */
      font-size: 15px;
      /* width: 50%; */
      align-items: center;
      align-self: center;
      border-radius: 4px;
      padding: 8px 15px;
      font-weight: 500;
      color: rgb(95, 95, 95);
      text-decoration: none;
      border: 1px solid;
      transition: 0.2s ease;
    }
  }
  .card a:hover {
    background-color: orange;
    color: white;
    border-color: white;
    padding: 8px 20px;
    font-size: 16px;
  }
  // background-color: #f7f7f7 !important;
  .card,
  .left-card {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.07);

    // background-color: #2c2e94;
    .image {
      height: 250px;
      background-position: center;
      background-size: cover;
    }
    .details {
      padding: 16px;
      .heading {
        a {
          color: $secondary;
          transition-duration: 0.3s;
          font-weight: 600;
        }
        a:hover {
          color: $primary;
        }
      }
      .summaryBox {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 20px;
      }
      .btn {
        background: linear-gradient(to right, #ef3e0f, #ffb800);
        border-radius: 0 !important;
        outline: 0;
        border: 0;
        width: 50%;
      }
      .btn:hover {
        opacity: 0.85;
      }
    }
    .ribbon {
      background-color: #ffc107;
      -webkit-box-shadow: 0 1px 0px rgba(0, 0, 0, 0.07);
      box-shadow: 0 1px 0px rgba(0, 0, 0, 0.07);
      border-radius: 0;
      color: $secondary;
      display: block;
      font-size: 20px;
      position: absolute;
      padding: 7px 15px;
      top: 20px;
      left: -15px;
      font-weight: bold;
    }

    .ribbon:after {
      content: '';
      position: absolute;
      top: auto;
      left: 4px;
      right: auto;
      bottom: -7px;
      background: #bb7a03;
      -webkit-transform: rotate(35deg);
      transform: rotate(35deg);
      height: 20px;
      width: 20px;
      z-index: -1;
    }
    .item-underlined {
      border-bottom: 0.5px solid #c2c2c2;
    }
    .item-underlined:last-child {
      border-bottom: none;
    }
    .category-title {
      font-size: 20px;
      color: #212529;
      cursor: pointer;
    }
    .category-title:hover {
      color: #fc8200;
      text-decoration: none;
    }
  }
  .title-underlined {
    border-bottom: 2px solid #ff9a02;
  }
}

@media (max-width: 991px) {
  .myBook {
    .card {
      .details {
        .btn {
          width: 100%;
        }
      }
    }
  }
}

.myBook-card {
  .card-image {
    // clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
    // height: 250px;
    background-repeat: no-repeat;
    background-size: cover;
    height: 250px;
    overflow: hidden;
    transition: 2s ease-in-out;
    width: 100%;
    border-radius: 10%;
    align-self: center;
  }

  .card {
    background-repeat: no-repeat;
    background-size: cover;
    // background-color: rgb(255, 255, 255);
    // min-height: 290px;
    // max-height: 450px;
    text-align: center;
    overflow: hidden;
    transition: all 1s ease 0s;
    text-decoration: none;
    border: 0px;
    box-shadow: none;

    .description {
      display: flex;
      margin-top: 20px;
      flex-direction: column;
      align-items: center;
      width: 90%;
      align-self: center;
      .item {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
        .key {
          font-weight: 600;
          color: rgb(145, 145, 145);
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        .value {
          font-weight: 600;
          color: black;
          text-transform: capitalize;
        }
      }
    }
    .buttonCont {
      width: 90%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      border-bottom: 1px solid rgb(192, 192, 192);
      align-self: center;
      padding-bottom: 10px;
    }
    h6 {
      font-size: 22px;
      font-family: system-ui;
      color: black;
      text-transform: uppercase;
      font-weight: 600;
      text-shadow: black;
    }
    .category {
      font-size: 22px;
      font-family: cursive;
      color: #d18f39;
      text-transform: capitalize;
      font-weight: 600;
      text-shadow: black;
      padding-top: 10px;
    }
    .price {
      color: rgb(0, 0, 0);
      border-radius: 20px;
      background-color: rgb(255, 239, 239);
      border-radius: 15px;
      align-self: center;
      padding: 4px 12px;
      margin-top: -10px;
    }
    .small-detail {
      font-size: 12px;
      /* font-family: cursive; */
      color: rgb(155, 155, 155);
      text-transform: capitalize;
    }
    a {
      font-size: 15px;
      background-color: #18b95e;
      align-items: center;
      align-self: center;
      border-radius: 4px;
      padding: 8px 15px;
      font-weight: 600;
      color: rgb(255, 248, 248);
      text-decoration: none;

      transition: 0.2s ease;
      width: 40%;
      cursor: pointer;
    }
    a:hover{
      background-color: #108F46;
      color: rgb(255, 255, 255);
      border-color: #F70103;
    }
    .back {
      font-size: 15px;
      align-items: center;
      align-self: center;
      border-radius: 4px;
      padding: 8px 15px;
      font-weight: 600;
      background-color: rgb(255, 255, 254);
      color: rgb(138, 136, 136);
      text-decoration: none;
      border: 1px solid rgb(146, 144, 144);
      transition: 0.2s ease;
      width: 40%;
    }
    .back:hover {
      background-color: #F70103;
      color: rgb(255, 255, 255);
      border-color: #F70103;
    }
    .rese {
      font-size: 15px;
      align-items: center;
      align-self: center;
      border-radius: 4px;
      padding: 8px 15px;
      font-weight: 600;
      background-color: #ff8f10;
      color: rgb(255, 255, 255);
      text-decoration: none;
    
      transition: 0.2s ease;
      width: 40%;
    }
    .rese:hover {
      background-color: #109fff;
      color: rgb(255, 255, 255);
      border-color: #F70103;
    }
  }
  // background-color: #f7f7f7 !important;
}

@media (max-width: 991px) {
  .myBook-card {
    .card {
      .details {
        .btn {
          width: 100%;
        }
      }
    }
  }
}
