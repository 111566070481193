body {
    margin: 0;
    padding: 0;
    overflow-y: hidden;
    
 }
 *, html {
    margin:0;
    padding:0;  
}


.topBar {
    overflow: hidden;
    padding: 33px 0;
    .logoCol {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .heading,
        p {
            color: $secondary;
        }
    }
    .headingCol {
        text-align: center;
        .heading,
        p {
            color: $secondary;
        }
    }
    .iconsCol {
        display: flex;
        justify-content: center;
        flex-direction: column;
        p {
            margin-bottom: 5px !important;
            i {
                width: 20px;
                text-align: center;
            }
        }
    }
}

.navBar {
    z-index: 999;
    margin-left: -15px;
    transition: all 0.5s ease;
    .navbar-toggler {
        color: $secondary !important;
        border-color: $secondary !important;
        outline: 0 !important;
        padding: 1px 6px !important;
        .navbar-toggler-icon {
            background-image: none;
            i {
                color: $secondary !important;
                font-size: 30px;
            }
        }
    }
    .navbar-brand {
        width: calc(100% - 56px);
        text-align: center;
        font-size: 27px;
    }
    .nav-item {
        .nav-link {
            font-weight: bold;
            text-transform: uppercase;
            color: white !important;
        }
        .nav-button {
            outline: 0 !important;
            border: none !important;
            background-color: transparent !important;
            cursor: pointer;
        }
        .nav-link:hover {
            color: $secondary !important;
        }
    }
    .dropdown-menu {
        margin: 0 !important;
        padding: 0 !important;
        .dropdown-item {
            padding: 9px 18px;
            border-bottom: 1px solid #ddd;
        }

        .dropdown-item:last-child {
            border-bottom: none;
        }

        .dropdown-item:hover {
            background-color: #ededed;
        }
    }
}

.navBar .nav-item .nav-link {
  font-weight: normal;
}

.navBar-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8) !important;
    padding: 10px 0;
    .navbar-toggler {
        color: white !important;
        border-color: white !important;
        .navbar-toggler-icon {
            i {
                color: white !important;
            }
        }
    }
    .nav-item {
        .nav-link:hover {
            color: $primary !important;
        }
    }
}

@media (max-width: 1290px) {
    .topBar {
        .headingCol {
            .heading {
                font-size: calc(1rem + 1.48vw);
            }
            p {
                font-size: calc(0.5rem + 0.5vw);
            }
        }
    }
}

@media (max-width: 991px) {
    .navBar {
        .nav-item {
            border-bottom: 1px solid $secondary;
        }
    }
    .navBar-fixed {
        .nav-item {
            border-bottom: 1px solid $primary;
        }
    }
}
@media (max-width: 767px) {
    .topBar {
        .headingCol,
        .iconsCol {
            display: none;
        }
    }
}
