.footerMenu {
    background-image: url("../images/footer/footer-bg.jpg");
    background-position: center;
    background-size: cover;
    color: white;
    .overlay {
        background: rgb(0,0,0);
        background: linear-gradient(0deg, rgba(0,0,0,0.5410539215686274) 34%, rgba(0,0,0,0.6867121848739496) 64%);
        padding: 60px 0;
    }
    hr {
        border-color: #e4e4e4 !important;
        border-width: 1px;
    }
    .socialIcons {
        display: flex;
        justify-content: space-around;
        height: 70px;
        width: 12rem;
        .iconBox {
            background: linear-gradient(
                180deg, rgba(44,46,148,0.6923144257703081) 34%, rgba(252,130,0,0.7063200280112045) 64%);
            display: inline-block;
            width: 40px;
            height: 40px;
            border: 1px solid white;
            line-height: 40px;
            text-align: center;
            transition: all 0.3s ease 0s;
            border-radius: 999px;
            cursor: pointer;
            a {
                display: block;
                color: white;
            }
        }
        .iconBox:hover {
            background-color: white;
            a {
                color: black;
            }
        }
    }
    .blogCol {
        .media {
            margin-bottom: 20px;
            img:hover {
                opacity: 0.7;
            }
            .media-body {
                h5 {
                    font-weight: normal;
                    a {
                        color: $primary;
                    }
                }
                a,
                span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                }
                a {
                    -webkit-line-clamp: 1;
                }
                span {
                    -webkit-line-clamp: 3;
                }
            }
        }
        .media:last-child {
            margin-bottom: 0;
        }
    }
    .instagramCol {
        .imagesBox {
            display: flex;
            flex-wrap: wrap;
            .single-image-box {
                margin-bottom: 10px;
                padding: 0 5px;
                width: 33.3333%;
                text-align: center;
            }
            .single-image-box:hover {
                opacity: 0.7;
            }
        }
    }
    .hr {
        border-color: #e4e4e4 !important;
        border-width: 1px;
        margin: 40px 0;
    }
    .footerText a:hover {
        color: $primary !important;
    }
}

@media (max-width: 767px) {
    .footerMenu {
        .linksCol {
            h5 {
                text-align: center;
            }
            ul {
                text-align: center;
            }
        }
        .col-12 {
            margin-bottom: 20px !important;
        }
        .col-12:last-child {
            margin-bottom: 0 !important;
        }
    }
}

@media (max-width: 420px) {
    .footerText {
        p {
            font-size: 12px;
        }
    }
}
// .RA-13-col-style{
//     background: #fc8200;
//     padding-top: 100px;
//     padding-bottom: 100px;
//    }

// .RA-13-col-style-rest{
//     // background: #2C2E94;
//     padding-top: 100px;
//     padding-bottom: 100px;
// }
.RA-13-txt-reserved{
    margin:  0px 0px -40px 0px !important;;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
}
.RA-13-txt-comp{
    color: #fff;
}
.RA-13-about-fnt-mrgn{
    margin: 10px 35px 0px 0px;
}
.RA-13-mrgn-lft{
  margin-left: 30px;
}
.RA-13-icon-mrgn{
    margin-right: 10px;
}
.RA-13-footer-img{
    width: 12rem;
    margin-bottom: 10px;
}
.RA-13-login-bg {
    background-image: url("../images/Auth/login-bg.jfif");
    background-position: center;
    background-size: cover;
    color: white;
}