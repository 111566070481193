.register {
  overflow-y: scroll;
  .title {
    margin-top: 15px;
    text-align: center;
  }

  .RFS-StepCircle.active {
    background-color: #fc8200;
  }

  .RFS-StepCircle.completed {
    background-color: #2c2e94;
  }
}
