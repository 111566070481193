// body,html{
//     height: 100%;
//     margin: 0;
//     background: #7F7FD5;
//        background: -webkit-linear-gradient(to right, #91EAE4, #86A8E7, #7F7FD5);
//         background: linear-gradient(to right, #91EAE4, #86A8E7, #7F7FD5);
//   }

.chat-chat {
  margin-top: auto;
  margin-bottom: auto;
}
.chat-card {
  height: 500px;
  border-radius: 15px !important;
  background-color: rgba(0, 0, 0, 0.4) !important;
}
.chat-contacts_body {
  padding: 0.75rem 0 !important;
  overflow-y: auto;
  white-space: nowrap;
}
.chat-msg_card_body {
  flex: 1 1 auto;
    max-height: 362px;
    min-height: 362px;
    padding: 1.25rem;
    overflow-y: auto
}
.chat-card-header {
  border-radius: 15px 15px 0 0 !important;
  border-bottom: 0 !important;
}
.chat-card-footer {
  border-radius: 0 0 15px 15px !important;
  border-top: 0 !important;
}
.chat-container {
  align-content: center;
}
.chat-search {
  border-radius: 15px 0 0 15px !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
}
.chat-search:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.chat-type_msg {
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
  height: 60px !important;
  overflow-y: auto;
}
.chat-type_msg:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.chat-attach_btn {
  border-radius: 15px 0 0 15px !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}
.chat-send_btn {
  border-radius: 0 15px 15px 0 !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}
.chat-search_btn {
  border-radius: 0 15px 15px 0 !important;
  background-color: rgba(0, 0, 0, 0.3) !important;
  border: 0 !important;
  color: white !important;
  cursor: pointer;
}
.chat-contacts {
  list-style: none;
  padding: 0;
}
.chat-contacts li {
  width: 100% !important;
  padding: 5px 10px;
  margin-bottom: 15px !important;
}
.chat-active {
  background-color: rgba(0, 0, 0, 0.3);
}
.chat-user_img {
  height: 70px;
  width: 70px;
  border: 1.5px solid #f5f6fa;
}
.chat-user_img_msg {
  height: 40px;
  width: 40px;
  border: 1.5px solid #f5f6fa;
}
.chat-img_cont {
  position: relative;
  height: 70px;
  width: 70px;
}
.chat-img_cont_msg {
  height: 40px;
  width: 40px;
}
.chat-online_icon {
  position: absolute;
  height: 15px;
  width: 15px;
  background-color: #4cd137;
  border-radius: 50%;
  bottom: 0.2em;
  right: 0.4em;
  border: 1.5px solid white;
}
.chat-offline {
  background-color: #c23616 !important;
}
.chat-user_info {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
}
.chat-user_info span {
  font-size: 20px;
  color: white;
}
.chat-user_info p {
  font-size: 10px;
  color: rgba(255, 255, 255, 0.6);
}
.chat-video_cam {
  margin-left: 50px;
  margin-top: 5px;
}
.chat-video_cam span {
  color: white;
  font-size: 20px;
  cursor: pointer;
  margin-right: 20px;
}
.chat-msg_cotainer {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  border-radius: 25px;
  background-color: #82ccdd;
  padding: 10px;
  position: relative;
  min-width: 75px;
}
.chat-msg_cotainer_send {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  border-radius: 25px;
  background-color: #78e08f;
  padding: 10px;
  position: relative;
  min-width: 75px;
}
.chat-msg_time {
  position: absolute;
  left: 0;
  bottom: -15px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 10px;
}
.chat-msg_time_send {
  position: absolute;
  right: 0;
  bottom: -15px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 10px;
}
.chat-msg_head {
  position: relative;
}
#action_menu_btn {
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
  cursor: pointer;
  font-size: 20px;
}
.chat-action_menu {
  z-index: 1;
  position: absolute;
  padding: 15px 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 15px;
  top: 30px;
  right: 15px;
  display: none;
}
.chat-action_menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.chat-action_menu ul li {
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 5px;
}
.chat-action_menu ul li i {
  padding-right: 10px;
}
.chat-action_menu ul li:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
}
@media (max-width: 576px) {
  .chat-contacts_card {
    margin-bottom: 15px !important;
  }
}
