.contactDetails {
    background-color: #fff;
    .card {
        height: 250px;
        padding: 40px;
        text-align: center;
        overflow: hidden;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        border: none;

        // https://stackoverflow.com/questions/43670994/how-to-transition-from-bottom-to-top-using-only-hover-and-without-using-before-o/43671830
        background-size: 200% 100%;
        background-image: linear-gradient(to left, #fff 50%, #1e3c72 50%);
        background-position: 100% 0;
        transition: background-position 0.2s ease-in-out, color 0.2s ease-in-out;
        // End of Transition; some part of this transition code is .card:hover
        i {
            font-size: 50px;
            margin-bottom: 10px;
        }
        a {
            color: black;
        }
    }
    .card:hover {
        background-position: 0% 0;
        color: #fff;
        i,
        h5 {
            color: $primary;
        }
        a,
        p {
            color: white;
        }
    }
}

.addressDetails {
    background-color: #fff;
    .card {
        height: 250px;
        padding: 40px;
        text-align: center;
        overflow: hidden;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        border: none;

        // https://stackoverflow.com/questions/43670994/how-to-transition-from-bottom-to-top-using-only-hover-and-without-using-before-o/43671830
        background-size: 200% 100%;
        background-image: linear-gradient(to left, #fff 50%, #1e3c72 50%);
        background-position: 100% 0;
        transition: background-position 0.2s ease-in-out, color 0.2s ease-in-out;
        // End of Transition; some part of this transition code is .card:hover
        i {
            font-size: 50px;
            margin-bottom: 10px;
        }
        a {
            color: black;
        }
    }
    .card:hover {
        background-position: 0% 0;
        color: #fff;
        i,
        h5 {
            color: $primary;
        }
        a,
        p {
            color: white;
        }
    }
}

.mapBox {
    height: 500px;
    .map {
        height: 500px;
    }
}

.contactForm {
    background: #fc8200;
    button {
        border-radius: 30px;
        width: 300px;
        max-width: 100%;
    }
}

@media (max-width: 991px) {
    .addressDetails {
        .col-12 {
            margin-bottom: 20px !important;
            .card {
                width: 280px;
                max-width: 100%;
                margin: 0 auto;
            }
        }
        .col-12:last-child {
            margin-bottom: 0 !important;
        }
    }
}
@media (max-width: 767px) {
    .contactDetails {
        .col-12 {
            margin-bottom: 20px !important;
        }
        .col-12:last-child {
            margin-bottom: 0 !important;
        }
    }
}
