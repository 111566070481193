.about {
    .card {
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.07);
        border: 0 !important;
        .image {
            height: 250px;
            background-position: center;
            background-size: cover;
        }
        .details {
            padding: 16px;
            .heading {
                a {
                    color: $secondary;
                    transition-duration: 0.3s;
                    font-weight: 600;
                }
                a:hover {
                    color: $primary;
                }
            }
            .summaryBox {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                margin-bottom: 20px;
            }
        }
        .ribbon {
            background-color: #ffc107;
            -webkit-box-shadow: 0 1px 0px rgba(0, 0, 0, 0.07);
            box-shadow: 0 1px 0px rgba(0, 0, 0, 0.07);
            border-radius: 0;
            color: $secondary;
            display: block;
            font-size: 20px;
            position: absolute;
            padding: 7px 15px;
            top: 20px;
            left: -15px;
            font-weight: bold;
        }

        .ribbon:after {
            content: "";
            position: absolute;
            top: auto;
            left: 4px;
            right: auto;
            bottom: -7px;
            background: #bb7a03;
            -webkit-transform: rotate(35deg);
            transform: rotate(35deg);
            height: 20px;
            width: 20px;
            z-index: -1;
        }
    }
    .active-link {
      color: #ffffff;
      background-color: #2c2e94;
      border-color: #2c2e94;
    }
    .quick-links {
        margin-top: 3%;
        margin-bottom: 3%;
    }
    .space {
        margin-left: 10px;
    }
}

@media (max-width: 991px) {
    .about {
        .card {
            .details {
                .btn {
                    width: 100%;
                }
            }
        }
        .space {
            margin-top: 10px;
        }
        .quick-links {
            flex-direction: column;
        }
    }
}
