.homeMain {
  .carousel-inner {
    height: 600px !important;
    .image {
      height: 600px;
      background-position: center;
      background-size: cover;
    }
  }
  .searchBar {
    height: 600px;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: rgba(0, 0, 0, 0.5);
    // background: -webkit-linear-gradient(
    //   to right bottom,
    //   rgba(12, 46, 96, 0.8),
    //   rgba(239, 71, 13, 0.5)
    // );
    // background: linear-gradient(
    //   to right bottom,
    //   rgba(12, 46, 96, 0.8),
    //   rgba(239, 71, 13, 0.5)
    // );
    .content {
      text-align: center;
      color: white;
      h1 {
        font-size: 50px;
        font-weight: bold;
      }
      span {
        color: $primary;
      }
    }
    .searchForm {
      display: flex;
      .search {
        flex-basis: 220px;
        width: 100%;
        height: 50px;
        border: 0;
        background-color: #f9f9f9;
      }
      #countryDropdown {
        padding-left: 12px;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
      }
      .search:focus,
      button:focus {
        outline: 0;
      }
      input {
        flex-basis: calc(100% - 490px) !important;
        padding: 25px 5px;
      }
      button {
        background: linear-gradient(to right, #ef3e0f, #ffb800);
        border: 0;
        border-radius: 0 50px 50px 0;
        color: #fff;
        font-size: 24px;
        flex-basis: 100px;
        transition: all 0.3s ease-in-out;
        width: 50px;
      }
      button:hover {
        opacity: 0.8;
      }
    }
  }
}

.about {
  .container {
    padding-top: 10px;
    padding-bottom: 10px;
    .description {
      text-align: justify;
      font-size: 18px;
      line-height: 33px;
      a {
        color: black;
        font-weight: bold;
      }
    }
  }
}

.features {
  background-color: #f9f9f9;
  .leftCol,
  .rightCol {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .imageCol {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.counters {
  background-image: url('../images/counters-bg.jpg');
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  .overlay {
    padding: 100px 0;
    background-color: rgba(0, 0, 0, 0.7);
  }
}

.courseCategories {
  background-color: #ffffff;
  .card-image {
    // clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
    // height: 250px;
    background-repeat: no-repeat;
    background-size: cover;
    height: 250px;
    width: auto;
    overflow: hidden;
    transition: 2s ease-in-out;
    background-position: center;
  }
  .card-image:hover {
    // clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
    // height: 250px;
    background-repeat: no-repeat;
    background-size: cover;
    transform: scale(1.08);
  }
  .card {
    // clip-path: polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%);
    // height: 250px;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgb(241, 241, 241);
    min-height: 290px;
    max-height: 450px;
    text-align: center;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: -0.5px solid;
    transition: all 1s ease 0s;
    color: rgb(66, 66, 66);
    text-decoration: none;
    h6 {
      margin-top: 25px;
      font-size: 22px;
      font-family: system-ui;
    }
    .title {
      border: none;
      font-size: 20px;
      cursor: pointer;
      height: 20px;
    }
    .title:hover {
      background: inherit;
      color: orange;
      font-size: 22px;
    }
    a {
      margin: 10px 20px 20px;
      /* background-color: orange; */
      font-size: 15px;
      /* width: 50%; */
      align-items: center;
      align-self: center;
      border-radius: 4px;
      padding: 8px 15px;
      font-weight: 500;
      color: rgb(95, 95, 95);
      text-decoration: none;
      border: 1px solid;
      transition: 0.2s ease;
    }
  }
  .card a:hover {
    background-color: orange;
    color: white;
    border-color: white;
    padding: 8px 20px;
    font-size: 16px;
  }
}

.coursePromotions {
  background-color: #f7f7f7;
  .course {
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
      text-align: left;
    }
  }
  .course:focus {
    outline: 0 !important;
  }
}

.clients {
  background-image: url('../images/road.jpg');
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  color: white;
  .overlay {
    padding: 100px 0;
    background-color: rgba(0, 0, 0, 0.3);
    img {
      width: 100px;
    }
  }
}

.freeTest {
  padding: 100px 0;
  background: #abbaab;
  background: -webkit-linear-gradient(to right, #abbaab, #eee);
  background: linear-gradient(to right, #abbaab, #eee);
  .content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    h2 {
      color: #444;
    }
    .btn {
      background: linear-gradient(90deg, #ef3e0f, #ffb800);
      color: white;
      border: none;
      border-radius: 0 50px 50px 0;
      box-shadow: none;
      flex-basis: 170px;
      text-transform: uppercase;
      padding: 15px 25px;
    }
    .btn:hover {
      opacity: 0.8;
    }
  }
}

// Can use globally
.customArrow {
  color: $primary;
  transition: all 0.5s ease 0s;
  outline: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}
.customArrow:hover {
  color: black;
}
.customArrow:focus {
  box-shadow: none !important;
  outline: 0 !important;
}

@media (max-width: 1200px) {
  .about {
    h1 {
      font-size: calc(1rem + 1.5vw);
    }
  }
}

@media (max-width: 991px) {
  .features {
    .rightCol {
      .media {
        margin-bottom: 16px;
      }
      .media:last-child {
        margin-bottom: 0;
      }
    }
  }
  .courseCategories {
    .col-12 {
      margin-bottom: 30px;
      .card {
        // width: 280px;
        max-width: 100%;
        margin: 0 auto;
      }
    }
    .col-12:last-child {
      margin-bottom: 0;
    }
  }
  .counters {
    .col-12 {
      margin-bottom: 30px;
    }
    .col-12:last-child {
      margin-bottom: 0;
    }
  }
  .freeTest {
    .content {
      h2 {
        font-size: 24px;
      }
      .btn {
        flex-basis: 150px;
        padding: 10px 20px;
      }
    }
  }
}

@media (max-width: 767px) {
  .homeMain {
    .searchBar {
      .content {
        h1 {
          font-size: calc(1.5rem + 1.5vw);
        }
      }
      .searchForm {
        display: block !important;
        text-align: center;
        .dropdown {
          width: 50%;
        }
        #regionDropdown {
          padding: 0 12px;
          border-top-right-radius: 50px;
          border-bottom-right-radius: 50px;
        }
        input {
          border-radius: 50px;
          padding-left: 15px;
          padding-right: 15px;
          margin: 20px 0;
        }
        button {
          border-radius: 50px;
          width: 200px;
          max-width: 100%;
          height: 50px;
          line-height: 50px;
        }
      }
    }
  }
  .features {
    .leftCol,
    .rightCol {
      .media {
        margin-bottom: 16px;
        .media-body {
          text-align: left !important;
        }
      }
    }
    .imageCol {
      margin: 30px 0;
    }
  }
  .counters {
    .col-12 {
      margin-bottom: 30px;
    }
    .col-12:last-child {
      margin-bottom: 0;
    }
  }
  .freeTest {
    .content {
      display: block !important;
      text-align: center;
      h2 {
        display: block !important;
        margin-bottom: 50px !important;
        font-size: 30px;
      }
      .btn {
        border-radius: 50px !important;
        width: 280px;
        max-width: 100%;
        padding: 15px 25px;
      }
    }
  }
}
.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  padding-right: 9pxx;
}
// .row {
//   margin-right: 0px;
//   margin-left: 0px;
// }
.RA-13-home-main {
  width: 12%;
  position: absolute;
  margin-top: -350px;
  margin-left: 200px;
}
.RA-13-promotion-btn {
  background: #fc8200;
  border: none;
  color: #fff;
  padding: 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  outline: none;
}
