.faq {
    background-color: #f1f1f1;
    .card {
        background-color: #f1f1f1;
        box-shadow: 0 1px 2px rgba(0,0,0,0.07), 
        0 2px 4px rgba(0,0,0,0.07), 
        0 4px 8px rgba(0,0,0,0.07), 
        0 8px 16px rgba(0,0,0,0.07),
        0 16px 32px rgba(0,0,0,0.07), 
        0 32px 64px rgba(0,0,0,0.07);
        padding: 15px;
        min-height: 300px;
        li {
            a {
                color: #333333;
                cursor: pointer;
                text-decoration: none;
            }
            padding: 10px 15px;
            margin-right: 2px;
            & {
                a.active {
                    color: #ffffff;
                    background-color:  #f58727;
                }
            }
        }
        li.active {
            background-color: #f58727;
        }
        li:last-child {
            margin-right: 0px;
        }
    }
    .card-body {
        border: 1px solid #f58727;
    }
}