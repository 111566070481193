.login,
.resetPassword {
  padding: 100px 0;
  .card {
    padding: 50px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: none;
    button {
      background-color: $secondary;
      color: white;
      font-weight: bold;
    }
    button:hover {
      border: 1px solid $secondary;
      color: $secondary;
      background-color: white;
    }
  }
}
.login {
  .card {
    a {
      color: $primary;
    }
  }
}

.facebook-container button {
  width: 100% !important ;
  margin-bottom: 1rem !important;
  background-color: #234cd4 !important ;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  font-size: 1rem;
  text-transform: capitalize;
  color: white;

  i {
    margin-right: 0.5rem;
  }
}

.facebook-container button:hover {
  border: 2px solid #002cee !important ;
  color: #002cee !important;
  background-color: white !important ;

  transition: all 0.2s ease-in-out;
}

@media (max-width: 576px) {
  .login,
  .resetPassword {
    padding: 100px 0;
    .card {
      padding: 50px 30px !important;
    }
  }
  .login {
    .card {
      p,
      a {
        font-size: 12px;
      }
    }
  }
}
.RA-13-login-cont{
  margin-bottom: 200px;
  margin-top: 50px;
}
.RA-13-login-col6{
  padding-top: 200px;
  padding-bottom: 200px;
}
.RA-13-login-pg{
  background: #fff;
  box-shadow: 0px 0px 8px 0px #ccc;
}
.RA-13-login-overflow{
  overflow-y: scroll;
  height: 100vh;
}
.RA-13-side-login{
  background: #00000054;
    padding: 70px 20px;
    border-radius: 4px;
    text-align: center;
    margin-top: 50px;
}
.RA-13-hr{
  border-width: 2px;
  border-color: #e4e4e4;
}
.RA-13-lgn-btn{
  background: #fc8200;
    color: #fff;
}
.RA-13-fnt-1{
  font-weight: 400 !important;
  color: #fc8200 !important;
}
.RA-13-fnt-size{
  font-size: 14px;
}