.ratings-card-container {
  margin-bottom: 3rem;
  
 
}

.ratings-card {
  img {
    width: 150px;
    height: 150px;
  }

  @media (max-width: 400px) {
    display: flex;
    flex-direction: row;

    h4 {
      text-align: center;
    }

    .ratings-image {
      display: flex;
      justify-content: center;
      margin-bottom: 1.5rem;
    }

    img {
      width: 200px;
      height: 150px;
    }
  }
}
